<template>
  <div class="public-home">
    <div class="layout-topbar">
      <router-link to="/" class="layout-topbar-logo">
        <img src="@/assets/logo.png" alt="">
        <span>DataPlus</span>
      </router-link>
      <ul class="layout-topbar-menu hidden lg:flex origin-top">
        <li>
          <router-link to="/users/login">
            <span>Login</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <div class="grid">
          <div class="col-12">
            <div class="card p-fluid">
              <div class="col-12">
                <h1>DataPlus</h1>
                <Fieldset :toggleable="true" legend="What's DataPlus?">
                  DataPlus is a service.
                </Fieldset>
                <br />
                <Fieldset :toggleable="true" legend="How does DataPlus work?">
                  DataPlus calculate the keyword values.
                </Fieldset>
                <br />
                <Fieldset
                  :toggleable="true"
                  legend="Are there any fees for DataPlus?"
                >
                  No, DataPlus is free now.
                </Fieldset>
                <br />
                <Fieldset :toggleable="true" legend="Why should login?">
                  Because DataPlus need your name and email to distinguish your
                  keyword research history.
                </Fieldset>
                <br />
                <Fieldset
                  :toggleable="true"
                  legend="Please Read Our Privacy Policy"
                >
                  <router-link to="/privacy-policy">
                    <span>Privacy Policy</span>
                  </router-link>
                </Fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AppFooter from "@/AppFooter.vue";
import Fieldset from "primevue/fieldset";

export default defineComponent({
  components: {
    AppFooter: AppFooter,
    Fieldset,
  },

  setup() {
    return {};
  },
});
</script>
